<template>
  <ModuleBase noBorder gray>
    <PageWrap wide :heading="$t('nav.integrations')">
      <div
        :class="[
          $style.base,
          {
            [$style.smallScreen]: $screen === 's'
          }
        ]"
      >
        <FiskalyModal
          v-if="showFiskalyModal"
          @close="showFiskalyModal = false"
        />
        <BaseSpinner v-if="loading" />
        <div v-else>
          <div v-if="enabledApps.length" :class="$style.enabled">
            <BaseHeading size="s" mb>
              {{ $t('global.enabled') }}
            </BaseHeading>
            <div :class="$style.apps">
              <AppCard
                v-for="(app, index) in enabledApps"
                :key="`${index}-enabled`"
                :app="app"
              />
            </div>
          </div>
          <div v-if="disabledApps.length">
            <BaseHeading size="s" mb>
              {{ $t('global.disabled') }}
            </BaseHeading>
            <div :class="$style.apps">
              <AppCard
                v-for="(app, index) in disabledApps"
                :key="`${index}-disabled`"
                :app="app"
                @enable="enableApp(app)"
              />
            </div>
          </div>
        </div>
      </div>
    </PageWrap>
  </ModuleBase>
</template>

<script setup lang="ts">
import ModuleBase from '@/modules/ModuleBase.vue';
import AppCard from './AppCard.vue';
import { useMutation, useQuery } from '@vue/apollo-composable';
import {
  GET_COMPANY_APPS,
  ENABLE_TREATWELL_MARKETPLACE,
  GET_APPS
} from './graphql';
import { useI18n } from 'vue-i18n';
import type { App } from './types';
import { useRouter } from 'vue-router';
import config from '@/config';
import { flash } from '@/helpers/ui';
import FiskalyModal from './FiskalyModal.vue';
import { useUserStore } from '@/stores/user';
import { useTreatwellStore } from '@/stores/treatwell';
import PageWrap from '../PageWrap.vue';

const { t } = useI18n();
const { user } = useUserStore();

const showTwMarketplaceSettings = user?.salonizedAdmin || user?.impersonated;

const apps = ref<App[]>([
  {
    key: 'franchise',
    enabled: false,
    heading: t('integrations.franchise.name'),
    description: t('integrations.franchise.description'),
    visual: '/img/integrations/franchise.svg',
    settingsRoute: 'integration-franchise'
  },
  {
    key: 'google_calendar',
    enabled: false,
    heading: t('integrations.google_calendar.name'),
    description: t('integrations.google_calendar.description'),
    visual: '/img/integrations/google-calendar.svg',
    settingsRoute: 'integration-google-calendar',
    enablePath: '/auth/google'
  },
  {
    key: 'mailchimp',
    enabled: false,
    heading: t('integrations.mailchimp.name'),
    description: t('integrations.mailchimp.description'),
    visual: '/img/integrations/mailchimp.svg',
    enablePath: '/auth/mailchimp',
    settingsRoute: 'integration-mailchimp'
  },
  {
    key: 'mollie',
    enabled: false,
    heading: t('integrations.mollie.name'),
    description: t('integrations.mollie.description'),
    visual: '/img/integrations/mollie.svg',
    settingsRoute: 'integration-mollie',
    enablePath: '/auth/mollie'
  },
  {
    key: 'stripe',
    enabled: false,
    heading: t('integrations.stripe.name'),
    description: t('integrations.stripe.description'),
    visual: '/img/integrations/stripe.svg',
    enablePath: '/auth/stripe'
  },
  {
    key: 'treatwell',
    enabled: false,
    heading: t('integrations.treatwell.name'),
    visual: '/img/integrations/treatwell.svg',
    description: t('integrations.treatwell.description'),
    enablePath: '/admin/apps/treatwell/new'
  },
  {
    key: 'treatwell_marketplace',
    enabled: false,
    heading: t('integrations.treatwell_marketplace.name'),
    visual: '/img/integrations/treatwell.svg',
    description: t('integrations.treatwell_marketplace.description'),
    settingsRoute: showTwMarketplaceSettings
      ? 'integration-treatwell-marketplace'
      : undefined,
    readMoreLink: !showTwMarketplaceSettings ? 'treatwell-landing' : undefined
  },
  {
    key: 'fiscalization',
    enabled: false,
    heading: t('integrations.fiskaly.name'),
    visual: '/img/integrations/fiskaly.svg',
    description: t('integrations.fiskaly.description'),
    disableUnavailable: true,
    alert: t('integrations.fiskaly.alert')
  },
  {
    key: 'google_reserve',
    enabled: false,
    heading: t('integrations.google_reserve.name'),
    visual: '/img/admin/rwg.svg',
    description: t('integrations.google_reserve.description'),
    enableRoute: {
      name: 'integration-google-reserve'
    },
    disableUnavailable: true
  }
]);

provide('apps', apps);

const enabledApps = computed(() => apps.value.filter((app) => app.enabled));
const disabledApps = computed(() => apps.value.filter((app) => !app.enabled));

const loading = ref(true);

const { onResult: onAppsResult } = useQuery(GET_APPS);
onAppsResult(({ data }) => {
  apps.value = apps.value.filter(
    (app) => !!data.apps.find((a) => app.key === a.key)
  );

  const { onResult } = useQuery(GET_COMPANY_APPS);
  onResult(
    ({
      data: {
        currentUser: { company }
      }
    }) => {
      company.apps.forEach((companyApp) => {
        const app = apps.value.find((a) => a.key === companyApp.key);
        if (app && companyApp.state === 'active') {
          app.enabled = true;
        }
      });

      loading.value = false;
    }
  );
});

const router = useRouter();
const showFiskalyModal = ref(false);

const enableApp = (app: App) => {
  if (app.key === 'franchise') {
    router.push({
      name: 'integration-franchise'
    });
  } else if (app.key === 'treatwell_marketplace') {
    const { treatwellStatus } = useTreatwellStore();
    if (treatwellStatus.hasVenue) {
      const { mutate } = useMutation(ENABLE_TREATWELL_MARKETPLACE);
      mutate({
        input: {}
      }).then(() => {
        app.enabled = true;
        flash(t('global.flash.integration_enabled'));
      });
    } else {
      router.push({
        name: 'treatwell-admin'
      });
    }
  } else if (app.key === 'fiscalization') {
    showFiskalyModal.value = true;
  } else if (app.enablePath) {
    window.location.replace(`${config.backendUrl}${app.enablePath}`);
  }
};
</script>

<style lang="scss" module>
.enabled {
  margin-bottom: $spacing * 2;
}

.apps {
  .base:not(.smallScreen) & {
    display: flex;
    flex-wrap: wrap;
    gap: $spacing;
  }

  & > * {
    .base:not(.smallScreen) & {
      width: 330px;
    }

    .base.smallScreen & {
      &:not(:last-child) {
        margin-bottom: $spacing;
      }
    }
  }
}
</style>
